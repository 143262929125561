import { Routes } from '@angular/router';
import { searchFilterIndex } from '../search/search-filter-index/search-filter-index.routes';

export const mainMenuRoutes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadComponent: () =>
      import('../home/home.page').then((m) => m.HomePage),
  },
  {
    path: 'story',
    loadComponent: () => import('../informations/story/story.page').then( m => m.StoryPage)
  },
  {
    path: 'warranty',
    loadComponent: () => import('../informations/warranty/warranty.page').then( m => m.WarrantyPage)
  },
  {
    path: 'delivery',
    loadComponent: () => import('../informations/delivery/delivery.page').then( m => m.DeliveryPage)
  },
  {
    path: 'terms-conditions',
    loadComponent: () => import('../informations/terms-conditions/terms-conditions.page').then( m => m.TermsConditionsPage)
  },
  {
    path: 'refund',
    loadComponent: () => import('../informations/refund-policy/refund-policy.page').then( m => m.RefundPolicyPage)
  },
  {
    path: 'product-verification',
    loadComponent: () => import('../informations/product-verification/product-verification.page').then( m => m.ProductVerificationPage)
  },
  {
    path: 'guide',
    loadComponent: () => import('../informations/online-shopping-guide/online-shopping-guide.page').then( m => m.OnlineShoppingGuidePage)
  },
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import('../privacy-policy/privacy-policy.page').then((m) => m.PrivacyPolicyPage),
  },
  {
    path: 'cart',
    loadComponent: () =>
      import('../cart/cart.page').then((m) => m.CartPage),
  },
  {
    path: 'view/:slug',
    loadComponent: () => import('../view-product/view-product.page').then(m => m.ViewProductPage)
  },
  {
    path: 'order',
    loadComponent: () => import('../order/order.page').then(m => m.OrderPage)
  },
  {
    path: 'signup',
    loadComponent: () => import('../auth/signup/signup.page').then(m => m.SignupPage)
  },
  {
    path: 'signin',
    loadComponent: () => import('../auth/signin/signin.page').then(m => m.SigninPage)
  },
  {
    path: 'forgot-password',
    loadComponent: () => import('../auth/forgot-password/forgot-password.page').then(m => m.ForgotPasswordPage)
  },
  {
    path: 'verification',
    loadComponent: () => import('../auth/verification/verification.page').then(m => m.VerificationPage)
  },
  {
    path: 'reset-password',
    loadComponent: () => import('../auth/reset-password/reset-password.page').then(m => m.ResetPasswordPage)
  },
  {
    path: 'personal-info',
    loadComponent: () => import('../settings/personal-info/personal-info.page').then( m => m.PersonalInfoPage)
  },
  {
    path: 'search',
    children: searchFilterIndex,
    loadComponent: () => import('../search/search-filter-index/search-filter-index.page').then(m => m.SearchFilterIndexPage)
  },
  {
    path: 'orders',
    loadComponent: () => import('../my-orders/index/index.page').then(m => m.IndexPage)
  },
  {
    path: 'orders/view-order',
    loadComponent: () => import('../my-orders/view-order/view-order.page').then(m => m.ViewOrderPage)
  },
  {
    path: 'shipping-address',
    loadComponent: () => import('../settings/shipping-address/shipping-address.page').then( m => m.ShippingAddressPage)
  },
  {
    path: 'validation',
    loadComponent: () => import('../validation/validation.page').then( m => m.ValidationPage)
  },
  {
    path: 'distributor',
    loadComponent: () => import('../informations/distributors/distributors.page').then( m => m.DistributorsPage)
  },
];
