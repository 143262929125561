import { Routes } from '@angular/router';

export const searchFilterIndex: Routes = [
  {
    path: '',
    redirectTo: 'results',
    pathMatch: 'full'
  },

  {
    path: 'results',
    loadComponent: () => import('../search-result/search-result.page').then((m) => m.SearchResultPage)
  },

]
