import { ScreenHelperService } from 'library-explorer';
import { Component, HostListener } from '@angular/core';
import { IonApp, IonRouterOutlet, Platform } from '@ionic/angular/standalone';
import { register } from 'swiper/element/bundle';
import { addIcons } from 'ionicons';
import * as ionIcons from 'ionicons/icons';
import { CartService } from './services/cart.service';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
  standalone: true,
  imports: [IonApp, IonRouterOutlet],
})
export class AppComponent {
  constructor(private platform: Platform, private screenHelperService: ScreenHelperService, private cartService: CartService) {
    this.initializeApp();
  }

  initializeApp() {
    addIcons(ionIcons);
    this.screenHelperService.onResize(this.platform.width());
    this.cartService.init();
  }

  // Screen Size Handler
  @HostListener('window:resize', ['$event'])
  private onResize(event: any) {
    this.screenHelperService.onResize(event.target.innerWidth);
  }
}
