import { Routes } from '@angular/router';
import { mainMenuRoutes } from './pages/main/main.routes';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'main',
    pathMatch: 'full',
  },

  {
    path: 'main',
    children: mainMenuRoutes,
    loadComponent: () => import('./pages/main/main.page').then(m => m.MainPage)
  },
  {
    path: 'privacy-policy',
    loadComponent: () => import('./pages/privacy-policy/privacy-policy.page').then(m => m.PrivacyPolicyPage)
  },
  {
    path: 'forgot-password',
    redirectTo: 'main/forgot-password'
  },
  {
    path: 'signup',
    redirectTo: 'main/signup'
  },
  {
    path: 'signin',
    redirectTo: 'main/signin'
  },
  {
    path: 'verification',
    redirectTo: 'main/verification'

  },
  {
    path: 'reset-password',
    redirectTo: 'main/reset-password'
  },
  {
    path: 'search',
    redirectTo: 'main/search'
  },
  {
    path: 'distributor',
    redirectTo: 'main/distributor'
  },
];
