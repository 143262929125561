import { Injectable } from '@angular/core';
import { StorageService, MART_USER_CART, ProductDTO } from 'library-explorer';
import { BehaviorSubject, Observable } from 'rxjs';
import { CartItemDTO } from '../interfaces/cart-item.interface';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private martCart$ = new BehaviorSubject<CartItemDTO[]>([]);
  private cartOrderSummary$ = new BehaviorSubject<any>(null);
  private isAllChecked$ = new BehaviorSubject<boolean>(false);

  constructor(private storageService: StorageService) { }

  get getAllCartItems(): Observable<CartItemDTO[]> {
    return this.martCart$.asObservable();
  }

  get getCartOrderSummary(): Observable<any> {
    return this.cartOrderSummary$.asObservable();
  }

  get getIsAllChecked(): Observable<boolean> {
    return this.isAllChecked$.asObservable();
  }

  init() {
    this.getCartItems();
  }

  emptyCart() {
    this.martCart$.next([]);
  }

  calculateCartOrderSummary(cartItems: CartItemDTO[]) {
    if (!cartItems || !cartItems.length) return;

    let sumTotalBeforeDiscount = 0;
    let sumTotalAfterDiscount = 0;
    let amountSaved = 0;
    let noOfItems = 0;

    cartItems
      .filter(item => item.selected)
      .forEach(item => {
        const { price, discount } = item.product;
        const itemTotalBeforeDiscount = price * item.quantity;
        const itemTotalAfterDiscount = item.total_price;

        sumTotalBeforeDiscount += itemTotalBeforeDiscount;
        sumTotalAfterDiscount += itemTotalAfterDiscount;
        amountSaved += itemTotalBeforeDiscount - itemTotalAfterDiscount;
        noOfItems += item.quantity;
      });

    const cartOrderSummary = {
      sumTotalBeforeDiscount,
      sumTotalAfterDiscount,
      amountSaved,
      noOfItems
    };

    this.cartOrderSummary$.next(cartOrderSummary);
  }

  async addToCart(product: ProductDTO, quantity: number = 1) {
    if (!product || quantity <= 0) return;

    // Calculate unit price and total price
    const unit_price = product.price - product.discount;
    const total_price = unit_price * quantity;

    // Create the CartItemDTO object
    const cartItem: CartItemDTO = {
      product,
      product_id: product.id,
      quantity,
      unit_price,
      total_price,
      selected: true
    };

    // Retrieve the existing cart items from storage
    let cartItems: string | null | CartItemDTO[] = await this.storageService.check4Value(MART_USER_CART);

    if (cartItems) {
      cartItems = JSON.parse(cartItems) as CartItemDTO[];

      // Check if the product already exists in the cart
      const existingCartItemIndex = cartItems.findIndex((item: CartItemDTO) => item.product_id === product.id);

      if (existingCartItemIndex !== -1) {
        // Update the quantity and total price if the product is already in the cart
        cartItems[existingCartItemIndex].quantity += quantity;
        cartItems[existingCartItemIndex].total_price += total_price;
      } else {
        // Add the new cart item to the cart
        cartItems.push(cartItem);
      }
    } else {
      // If there are no existing items, create a new cart with the current item
      cartItems = [cartItem];
    }

    // Store the updated cart items in storage
    await this.storageService.setStorageValue(MART_USER_CART, JSON.stringify(cartItems));
    this.getCartItems();
  }

  async removeFromCart(product: ProductDTO) {
    if (!product) return;

    let cartItems: string | null | CartItemDTO[] = await this.storageService.check4Value(MART_USER_CART);
    if (cartItems) {
      cartItems = JSON.parse(cartItems) as CartItemDTO[]
      const cartIndex = cartItems.findIndex(
        (item: CartItemDTO) => item.product_id === product.id
      );
      if (cartIndex !== -1) {
        cartItems.splice(cartIndex, 1);
        this.storageService.setStorageValue(MART_USER_CART, JSON.stringify(cartItems));
      }
    }
    this.getCartItems();
  }

  async isProductInCart(product: ProductDTO): Promise<boolean> {
    try {
      const cartItems = await this.storageService.check4Value(MART_USER_CART);
      if (!cartItems) return false;

      const parsedCartItems = JSON.parse(cartItems);
      return parsedCartItems.some((item: CartItemDTO) => item.product_id === product.id);
    } catch {
      return false;
    }
  }

  async unselectCartItem(cartItem: CartItemDTO) {
    let cartItems: CartItemDTO[] = await this.getCartItemsFromStorage();
    cartItems = cartItems.map(item =>
      item.product_id === cartItem.product_id ? { ...item, selected: false } : item
    );
    await this.saveCartItemsToStorage(cartItems);
  }

  async selectCartItem(cartItem: CartItemDTO) {
    let cartItems: CartItemDTO[] = await this.getCartItemsFromStorage();
    cartItems = cartItems.map(item =>
      item.product_id === cartItem.product_id ? { ...item, selected: true } : item
    );
    await this.saveCartItemsToStorage(cartItems);
  }

  async selectAllCartItems(status: boolean) {
    let cartItems: CartItemDTO[] = await this.getCartItemsFromStorage();
    cartItems = cartItems.map(item => ({ ...item, selected: status }));
    await this.saveCartItemsToStorage(cartItems);
    this.getCartItems();
  }

  private async confirmAllChecked(cartItems: CartItemDTO[]) {
    // check if all items in the cart is checked
    const allItemsSelected = cartItems.every(item => item.selected);
    this.isAllChecked$.next(allItemsSelected);
  }

  private async getCartItems() {
    let cartItems: CartItemDTO[] = await this.getCartItemsFromStorage();
    this.martCart$.next(cartItems);
    this.calculateCartOrderSummary(cartItems);

    this.confirmAllChecked(cartItems)
  }

  private async getCartItemsFromStorage(): Promise<CartItemDTO[]> {
    const cartItems = await this.storageService.check4Value(MART_USER_CART);
    return cartItems ? JSON.parse(cartItems) : [];
  }

  private async saveCartItemsToStorage(cartItems: CartItemDTO[]) {
    await this.storageService.setStorageValue(MART_USER_CART, JSON.stringify(cartItems));
    this.calculateCartOrderSummary(cartItems);

    await this.confirmAllChecked(cartItems)
  }
}
