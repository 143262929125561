import { APP_INITIALIZER, enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { apiUrlInterceptor } from './app/interceptors/api-url.interceptor';
import { authInterceptor } from './app/interceptors/auth.interceptor';
import { indicatorInterceptor, responseInterceptor } from 'library-explorer';
import { AuthenticationService } from './app/services/authentication.service';
import { initializeAppFactory } from './app/factories/initialize.factory';
import { httpXsrfInterceptor } from './app/interceptors/http-csrf.interceptor';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
    provideRouter(routes),
    provideHttpClient(
      // registering interceptors
      withInterceptors([apiUrlInterceptor, authInterceptor, indicatorInterceptor, httpXsrfInterceptor, responseInterceptor])
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AuthenticationService],
      multi: true
    }
  ],
});
